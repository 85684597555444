import classNames from "classnames";
import { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import chevLeft from "../../../../assets/New-images/chevron-left-blk.svg";
import {
  getCourseClassesById,
  getCourseLmsById,
  unlinkACourseToClass,
  updateClassDetailById
} from "../../../../store/knowledge/actions";
import { capitalizeFirstLetter } from "../../../../utils/appUtils";
import ClassAdmins from "./ClassAdmins";
import ClassLearners from "./ClassLearners";
import LinkCourseToAgent, { lmsListData } from "./LinkCourseModal";

import { useTranslation } from "react-i18next";
import { HelperService } from "../../../../services/helper.service";

const helperService = new HelperService();

function UpdateClasses() {
  const { t } = useTranslation('translation');
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [classDetail, setClassDetail] = useState(null);
  const [activeKey, setActiveKey] = useState("admins");
  const [name, setName] = useState("");
  const [courseLMS, setCourseLMS] = useState(null);
  useEffect(() => {
    funGetCourseClassesById();
    fungetCourseLmsById();
  }, []);

  const funGetCourseClassesById = async () => {
    try {
      const response: any = await dispatch(
        getCourseClassesById(params.classId)
      );
      setName(response.name)
      setClassDetail(response);
    } catch (error: any) {
      history.goBack();
    }
  };

  const fungetCourseLmsById = async () => {
    try {
      const response: any = await dispatch(getCourseLmsById(params.classId));
      setCourseLMS(response);
    } catch (error: any) {
      history.goBack();
    }
  };

  const funUnlinkACourseToClass = async () => {
    try {
      const response: any = await dispatch(
        unlinkACourseToClass(params.classId)
      );
      setCourseLMS(null);
    } catch (error: any) {
      history.goBack();
    }
  };

  const funUpdateClassDetailById = async (e: any) => {
    try {
      const response: any = await dispatch(
        updateClassDetailById(params.classId, {
          name: classDetail?.name,
        })
      );
      await funGetCourseClassesById();
      helperService.showToaster('Classname updated successfully.', 'success');
    } catch (error: any) {}
  };

  const [showCreateClassModal, setShowCreateClassModal] = useState(false);
  const showCreateClassModalHandler = async () => {
    setShowCreateClassModal(!showCreateClassModal);
  };

  return (
    <section className="page-mid-wraper h-without-foter">
      <LinkCourseToAgent
        show={showCreateClassModal}
        onHide={showCreateClassModalHandler}
        confirmHandler={() => {
          showCreateClassModalHandler();
          fungetCourseLmsById();
        }}
        id={classDetail ? classDetail._id : null}
        courseLMS={courseLMS}
      />
      <div>
        <Row>
          <Col md={12}>
            <h1 className="mb-24 d-flex align-items-center">
              <button
                className="btn py-0 mr-2"
                onClick={() => {
                  history.push('/user-management?tab=classes');
                }}
              >
                <img src={chevLeft} alt="" />
              </button>
              {name ? name : 'Class Name'}
            </h1>

            <h2 className="font-1 mb-1 font-600">{t("Manage Class")}</h2>

            <h2 className="mb-24 font-weight-normal">
              {t("Manage learners and admins within the class")}
            </h2>
          </Col>
          <Col md={12}>
            <div className="form-group mb-24">
              <label className="font-600 font-16">{t("Class Name")}</label>
              <div className="d-flex">
                <input
                  className="form-control h-auto radius-8"
                  placeholder={t("Name of Class")}
                  defaultValue={classDetail?.name || ""}
                  onChange={(e: any) => {
                    setClassDetail({
                      ...classDetail,
                      name: e.target.value,
                    });
                  }}
                />

                <button
                  disabled={!classDetail || !classDetail.name}
                  className={classNames({
                    "btn font-sm ml-3 text-nowrap": true,
                    "bg-grey-head border clr-grey-txt":
                      !classDetail || !classDetail.name,
                    "btn-primary": classDetail && classDetail.name,
                  })}
                  onClick={(e: any) => {
                    funUpdateClassDetailById(e);
                  }}
                >
                  {t("Save Changes")}
                </button>
              </div>
            </div>

            <div className="form-group">
              <label className="font-600 font-16">
                {t("Manage Class")}
                {!courseLMS && (
                  <button
                    className="btn btn-link ml-auto py-1"
                    onClick={showCreateClassModalHandler}
                  >
                    {t("+ Add")}
                  </button>
                )}
              </label>
              {/* {courseLMS && ( */}
              <div className="table-responsive manage-table">
                <table className="table font-16 font-600">
                  <thead>
                    <tr>
                      <th className="border-0 font-600 pl-lg-4 py-2">LMS</th>
                      <th className="border-0 font-600 pl-lg-4 py-2">
                        {courseLMS?.provider === "canvas" && courseLMS?.lms_course?.name ? t("Course Name") : t("Course ID")}
                      </th>
                      <th className="border-0 font-600 pl-lg-4 py-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pl-lg-4">
                        {courseLMS && lmsListData.find((e: any) => e.value === courseLMS?.provider).label}
                      </td>
                      <td className="pl-lg-4">
                        {courseLMS?.provider === "canvas" && courseLMS?.lms_course?.name ? courseLMS?.lms_course?.name : courseLMS?.course_id}
                      </td>
                      <td className="pl-lg-4">
                        {courseLMS && (
                          <>
                            <button
                              className="btn btn-outline-primary border-0 font-600 p-0 mr-3"
                              onClick={showCreateClassModalHandler}
                            >
                              {t("Edit")}
                            </button>
                            <button
                              className="btn btn-outline-danger border-0 font-600 p-0"
                              onClick={funUnlinkACourseToClass}
                            >
                              {t("Delete")}
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* )} */}
            </div>

            {classDetail && (
              <div className="knowledge-viewby">
                <Tabs
                  defaultActiveKey={activeKey}
                  onSelect={(k) => {
                    setActiveKey(k);
                  }}
                >
                  <Tab eventKey="admins" title={t("Admins")}>
                    <ClassAdmins
                      activeKey={activeKey}
                      data={classDetail ? [...classDetail.admins]: []}
                      getClassDetails={funGetCourseClassesById}
                      className={classDetail?.name}
                    />
                  </Tab>
                  <Tab eventKey="learners" title={t("Learners")}>
                    <ClassLearners
                      activeKey={activeKey}
                      data={classDetail ? [...classDetail.students]: []}
                      getClassDetails={funGetCourseClassesById}
                      confirmHandler={funGetCourseClassesById}
                      className={classDetail?.name}
                    />
                  </Tab>
                </Tabs>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default UpdateClasses;
