import ApiUtil from "../utils/apiUtil";
import {
  getAgentFromStorage,
  serialize,
  convertArrayToMarkdownFormat,
  serializeFilter,
} from "../utils/appUtils";
import DataApiUtil from "../utils/dataApiUtils";
import KnowledgeService from "./KnowledgeService";

type Type = any;
const groupService = new KnowledgeService();

export default class QuizService {
  async getAllQuizzes(params: any): Promise<Type> {
    const sortingQuery = `${
      params.sort_field && params.sort_order
        ? `&sort_field=${params.sort_field}&sort_order=${params.sort_order}`
        : ""
    }`;

    const knowledge_group_id = params.knowledge_group_ids
      ? `&knowledge_group_ids=${params.knowledge_group_ids}`
      : "";
    const typedQuery = `${params.q ? `&q=${params.q}` : ""}`;
    const hasTopLevelAppliedQuery = params.includes_all_subgroups
      ? `&includes_all_subgroups=true`
      : "";
    const paramStatus = params?.isArchive ? "&status=archived" : "";
    const type = params.type;

    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes?agent_id=${getAgentFromStorage()}${sortingQuery}${typedQuery}${knowledge_group_id}${hasTopLevelAppliedQuery}${paramStatus}&type=${type || 'default'}`,
      method: "GET",
    });
    return res;
  }

  async createQuizz(data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: data,
    });
    return res;
  }

  async createQuizButtons(data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: data,
    });
    return res;
  }

  async getQuizAlerts(quizId: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes/${quizId}/alerts?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }
  async connectToCanvas(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `integration/canvas-integrations/current?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }
  async connectToOauth2Lms(lmsCode: string, requests_new_state: boolean = false): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `integration/${lmsCode}-integrations/current?agent_id=${getAgentFromStorage()}${requests_new_state ? "&requests_new_state=true" : ""}`,
      method: "GET",
    });
    return res;
  }
  async updateQuizAlerts(quizId: any, updatedAlerts: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes/${quizId}/alerts?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        alerts: [...updatedAlerts],
      },
    });
    return res;
  }

  async getAllQuizSubmissions(quizId: any, filtersData: any): Promise<Type> {
    // let requestUrl = '';
    // if (connectionIds) {
    //   requestUrl = requestUrl + `&connection_id=${connectionIds}`;
    // }
    const hasQueryParamPresent = quizId.split("?");
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes/${
        hasQueryParamPresent.length > 1 ? hasQueryParamPresent[0] : quizId
      }/submissions?${
        serializeFilter(filtersData) ? serializeFilter(filtersData) + "&" : ""
      }agent_id=${getAgentFromStorage()}${
        hasQueryParamPresent.length > 1 ? "&" + hasQueryParamPresent[1] : ""
      }`,
      method: "GET",
    });
    return res;
  }
  async getIntegrationCurrentCourses(provider: string, isLinked?: boolean): Promise<Type> {
    const url = isLinked ? `integration/${provider}-integrations/current/courses?agent_id=${getAgentFromStorage()}&is_linked=true` : `integration/${provider}-integrations/current/courses?agent_id=${getAgentFromStorage()}`

    const res = await ApiUtil.SendAsync<any>({
      url,
      method: "GET",
    });
    return res;
  }

  async getSubmissionsBySubmissionId(
    quizId: any,
    submission_id: any
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes/${quizId}/submissions/${submission_id}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getAssessmentsBasedOnCourses(course_id: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `integration/canvas-integrations/current/courses/${course_id}/assignments?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async updateSubmissionsAcknowledge(
    quizId: any,
    submission_id: any,
    data: any
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes/${quizId}/submissions/${submission_id}?agent_id=${getAgentFromStorage()}`,
      method: "PATCH",
      data: {
        ...data,
      },
    });
    return res;
  }

  async submitAssessment(data: any): Promise<Type> {
    const query: string =
      data.filtersData && data.filtersData.connection_ids
        ? `&connection_id=${data.filtersData.connection_ids}`
        : "";
    const res = await ApiUtil.SendAsync<any>({
      url: `integration/canvas-integrations/current/courses/${
        data.course_id.id
      }/assignments/${
        data.assessment_id.id
      }/score-submissions?agent_id=${getAgentFromStorage()}${query}`,
      method: "POST",
      data: {
        quiz_id: data.quiz_id,
        score_type: data.score,
      },
    });
    return res;
  }

  async getQuizFeatures(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `feature/agent-features?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getLearningSubmissionsReviews(
    quizId: string,
    status: string = null,
    filtersData: any = null
  ): Promise<Type> {
    let q = "";
    const hasQueryParamPresent = quizId.split("?");
    let filters: any = {
      quiz_id:
        hasQueryParamPresent.length > 1 ? hasQueryParamPresent[0] : quizId,
    };
    if (filtersData) {
      filters = {
        ...filters,
        ...filtersData,
        status: status || null,
      };
    }

    const res = await ApiUtil.SendAsync<any>({
      url: `learning/submission-reviews?${serializeFilter(
        filters
      )}&agent_id=${getAgentFromStorage()}${
        hasQueryParamPresent.length > 1 ? "&" + hasQueryParamPresent[1] : ""
      }`,
      method: "GET",
    });
    return res;
  }

  async getSubmissionsReviewsByReviewId(reviewId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/submission-reviews/${reviewId}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async updateReviews(reviewId: string): Promise<Type> {
    const hasQueryParamPresent = reviewId.split("?");
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/submission-reviews/${
        hasQueryParamPresent.length > 1 ? hasQueryParamPresent[0] : reviewId
      }?agent_id=${getAgentFromStorage()}${
        hasQueryParamPresent.length > 1 ? "&" + hasQueryParamPresent[1] : ""
      }`,
      method: "PATCH",
    });
    return res;
  }

  async updateQuiz(quizId: string, data: any): Promise<Type> {
    const hasQueryParamPresent = quizId.split("?");
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes/${
        hasQueryParamPresent.length > 1 ? hasQueryParamPresent[0] : quizId
      }?agent_id=${getAgentFromStorage()}${
        hasQueryParamPresent.length > 1 ? "&" + hasQueryParamPresent[1] : ""
      }`,
      method: "PATCH",
      data: {
        ...data,
      },
    });
    return res;
  }

  async deleteQuiz(quizId: string, isArchive?: boolean): Promise<Type> {
    const paramArch = isArchive ? "&is_archiving=1" : "";
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes/${quizId}?agent_id=${getAgentFromStorage()}${paramArch}`,
      method: "DELETE",
    });
    return res;
  }

  async restoreQuiz(quizId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quizzes/${quizId}/restore?agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  async getLinkedIntentsBasedOnQuizAndQuestions(
    quizId: string,
    questionId: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/quizzes/${quizId}/questions/${questionId}/relevant-intents?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getLinkedQuestionsBasedOnQuizAndQuestions(
    quizId: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/quizzes/${quizId}/link-knowledge?agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  // Question Bank
  async getAllQuestionBank(params: any): Promise<Type> {
    const sortingQuery = `${
      params.sort_field && params.sort_order
        ? `&sort_field=${params.sort_field}&sort_order=${params.sort_order}`
        : ""
    }`;

    const knowledge_group_id = params.knowledge_group_ids
      ? `&knowledge_group_ids=${params.knowledge_group_ids}`
      : "";
    const typedQuery = `${params.q ? `&q=${params.q}` : ""}`;
    const hasTopLevelAppliedQuery = params.includes_all_subgroups
      ? `&includes_all_subgroups=1`
      : "";
    const show_sub_question = `&show_sub_question=1`;
    const paramStatus = params?.isArchive ? "&status=archived" : "";
    const idParam = params?.ids
      ? "&id=" + params?.ids.reduce((id1: any, id2: any) => id1 + "," + id2)
      : "";
    const custom_attribute_1 = params?.custom_attribute_1
      ? "&custom_attribute_1=" + params?.custom_attribute_1
      : "";
    const pagination = `${
      params.page && params.page_size
        ? `&page=${params.page}&page_size=${params.page_size}`
        : ""
    }`;
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions?agent_id=${getAgentFromStorage()}${sortingQuery}${typedQuery}${knowledge_group_id}${hasTopLevelAppliedQuery}${show_sub_question}${paramStatus}${idParam}${custom_attribute_1}${pagination}`,
      method: "GET",
    });
    return res;
  }

  async deleteQuestionBankById(
    quiz_question_id: any,
    isArchive: boolean
  ): Promise<Type> {
    const paramArch = isArchive ? "&is_archiving=1" : "";
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/${quiz_question_id}?agent_id=${getAgentFromStorage()}${paramArch}`,
      method: "DELETE",
    });
    return res;
  }

  async restoreQuestion(quiz_question_id: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/${quiz_question_id}/restore?agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  async updateQuestionFromQuestionBank(
    quiz_question_id: string,
    data: any
  ): Promise<Type> {
    let res;
    const question: any = data?.questions.find(
      (q: any) => q.id === quiz_question_id
    );
    const newSubs: any = [];
    if (question.type === "group") {
      data?.questions.forEach(async (subQes: any) => {
        if (subQes.parent_id === question?.id) {
          delete subQes.quiz_question_id;
          delete subQes.topic;
          delete subQes.invalid;
          delete subQes.keywords;
          delete subQes.topic_id;
          if (subQes.is_saved !== false) {
            delete subQes.agent_id;
            delete subQes.author;
            delete subQes.breadcrumbs;
            delete subQes.created_at;
            delete subQes.document_sections;
            delete subQes.knowledge_group_ids;
            delete subQes.language_code;
            delete subQes.organisation_id;
            delete subQes.quizzes;
            delete subQes.status;
            delete subQes.updated_at;
            delete subQes._id;
            await ApiUtil.SendAsync<any>({
              url: `quiz/questions/${
                subQes.id
              }?agent_id=${getAgentFromStorage()}`,
              method: "PATCH",
              data: subQes,
            });
          } else {
            // delete subQes.is_saved;
            newSubs.push({
              ...subQes,
              keywords: subQes.keywords ? subQes.keywords : null,
            });
            // await ApiUtil.SendAsync<any>({
            //   url: `quiz/questions?agent_id=${getAgentFromStorage()}`,
            //   method: "POST",
            //   data: subQes,
            // });
          }
        }
      });
    }
    if (newSubs.length > 0) {
      const response: any = await this.generateQuestionBank({
        questions: newSubs,
      });
      // if(response?.data?.data.quiz_questions){
      //   await groupService.addEntityToKnowledgeGroup(
      //     question.knowledge_group_ids[0],
      //     "question",
      //     response?.data?.data.quiz_questions.map( (q: any) => q.id)
      //   );
      // }
    }
    delete question.quiz_question_id;
    delete question.topic;
    delete question.invalid;
    delete question.keywords;
    delete question.topic_id;
    res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/${quiz_question_id}?agent_id=${getAgentFromStorage()}`,
      method: "PATCH",
      data: question,
    });

    return res;
  }

  async generateQuestionBank(data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: data,
    });
    return res;
  }

  async generateQuestionBankV2(quizId: string, data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `/quiz/quizzes/${quizId}/add-to-bank?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: data,
    });
    return res;
  }

  async getQuizQuestionById(quiz_question_id: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/${quiz_question_id}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async updateQuizQuestionById(
    quiz_question_id: string,
    data: any
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/${quiz_question_id}?agent_id=${getAgentFromStorage()}`,
      method: "PATCH",
      data: data,
    });
    return res;
  }

  async deleteQuizQuestionBankById(quiz_question_id: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/${quiz_question_id}?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
    });
    return res;
  }

  async getQuizQuestionBankGenerateTags(
    quiz_question_id: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/${quiz_question_id}/generate-tags?agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  async questionBankLinkDocumentSections(
    quiz_question_id: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/${quiz_question_id}/link-document-sections?agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  async updateQuestionBankLinkDocumentSections(
    quiz_question_id: string,
    data: any
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/${quiz_question_id}/document-section-ids?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: data,
    });
    return res;
  }

  async updateKeywordsOfQuestion(
    quiz_question_id: string,
    data: string[]
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/${quiz_question_id}/tags?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        tags: data,
      },
    });
    return res;
  }

  async getQuizDocumentsContents(documentId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quiz-documents/${documentId}/contents?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async saveQuizQuestionsBasedOnFile(payload: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quiz-questions?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: payload,
    });
    return res;
  }

  async quizDocumentQuestions(payload: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quiz-document-questions?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: payload,
    });
    return res;
  }

  async startQnaDocGen(payload: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quiz-extraction-jobs?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: payload,
    });
    return res;
  }

  async getQnaGenFromJobId(jobId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `/quiz/quiz-extraction-jobs/${jobId}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getQuizDocumentTableJobsQuestions(documentId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quiz-documents/${documentId}/table-jobs?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      // data: payload
    });
    return res;
  }

  async getQuizDocumentTableJobsByJobId(
    documentId: string,
    jobId: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/quiz-documents/${documentId}/table-jobs/${jobId}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
      // data: payload
    });
    return res;
  }

  async getMcqDistractors(payload: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/generate-mcq-distractors?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: payload,
    });
    return res;
  }
}
