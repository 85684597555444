import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import {
  getAllLevels,
  getAllModulesFromYear,
} from "../../../store/knowledge/actions";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState } from "react";
import closeImg from "../../../assets/images/close-blk.svg";

export const SelectModalModule = (props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [modules, setModules] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const horizontalScrollRef = useRef();

  useEffect(() => {
    if (!props.show) {
      return;
    }
    getAllYearsFun();
    setSelectedGroupId(props.groupId ? props.groupId : null);
    const target: any = horizontalScrollRef.current;
    if (target) {
      let mouseDown = false;
      let startX: any;
      let scrollLeft: any;

      target.addEventListener("wheel", (e: any) =>
        horizontalScrollHandler(e, target)
      );

      const startDragging = (e: any) => {
        mouseDown = true;
        startX = e.pageX - target.offsetLeft;
        scrollLeft = target.scrollLeft;
      };

      const stopDragging = (e: any) => {
        mouseDown = false;
      };

      const move = (e: any) => {
        e.preventDefault();
        if (!mouseDown) {
          return;
        }
        const x = e.pageX - target.offsetLeft;
        const scroll = x - startX;
        target.scrollLeft = scrollLeft - scroll;
      };

      // Add the event listeners
      target.addEventListener("mousemove", move, false);
      target.addEventListener("mousedown", startDragging, false);
      target.addEventListener("mouseup", stopDragging, false);
      target.addEventListener("mouseleave", stopDragging, false);

      return () => {
        target.removeEventListener("wheel", (e: any) =>
          horizontalScrollHandler(e, target)
        );
        target.removeEventListener("mousemove", move);
        target.removeEventListener("mousedown", startDragging);
        target.removeEventListener("mouseup", stopDragging);
        target.removeEventListener("mouseleave", stopDragging);
      };
    }
  }, [props.show]);

  const horizontalScrollHandler = (event: any, target: any) => {
    const toLeft = event.deltaY < 0 && target.scrollLeft > 0;
    const toRight =
      event.deltaY > 0 &&
      target.scrollLeft < target.scrollWidth - target.clientWidth;

    if (toLeft || toRight) {
      event.preventDefault();
      target.scrollBy({ left: event.deltaY, behavior: "smooth" });
    }
  };

  const onSelectGroupId = (level: string) => {
    setSelectedGroupId(level);
  };

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response.filter((res: any) => res.group_type !== "system")]);
      response &&
        response.length > 0 &&
        getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
    } catch (error: any) {
      setYears([]);
    }
  };

  const getAllModulesFromYearFun = async (year: any) => {
    setSelectedYear(year);
    try {
      const response: any = await dispatch(getAllModulesFromYear(year._id));
      setModules([...response]);
    } catch (error: any) {
      setModules([]);
    }
  };

  const confirmCategoryUpdatehandler = (
    selectedGroupId: any,
    extraParams: boolean = false,
    key: string
  ) => {
    setSelectedGroupId(selectedGroupId);
    props.confirmCategoryUpdate(selectedGroupId, extraParams, key);
  };

  const isGroupInvalid = !selectedGroupId || selectedGroupId === "undefined";

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="move-selected-modal modal-800 toolkit-select-module-modal"
      onHide={props.onHide}
      contentClassName="scroll-wrap"
    >
      <ModalHeader className="clr-black py-3 px-4 justify-content-between border-bottom">
        <ModalTitle id="contained-modal-title-vcenter">
          <h2 className="mb-0 font-1 ">{props.header ? props.header : ""}</h2>
        </ModalTitle>
        <button className="btn" onClick={props.onHide}>
          <img src={closeImg} alt="" />
        </button>
      </ModalHeader>
      <ModalBody className="px-4">
        <p className="font-16 mb-3 font-400">{props.subHeader}</p>
        <div className="level-tab-wraper">
          <div className="levels-row mb-1 d-flex align-items-center ">
            <div
              className="horizontal-scroll "
              id="levels-wrap"
              ref={horizontalScrollRef}
            >
              <h2 className="mb-0 mr-3 font-1">{t("Level:")}</h2>
              {years.map((year: any) => {
                return (
                  <button
                    className={classNames({
                      "btn btn-tabs ": true,
                      active: selectedYear && year._id === selectedYear._id,
                    })}
                    onClick={(e: any) => {
                      e.preventDefault();
                      getAllModulesFromYearFun(year);
                    }}
                  >
                    {year?.name}
                  </button>
                );
              })}
            </div>
          </div>
          {years.length > 0 && (
            <div className="knowledge-box p-0 build-knowbox text-left d-flex flex-column bg-white">
              <div className="d-flex justify-content-between align-items-center px-3 py-2 bg-grey-head">
                <h2 className="mb-0 font-1">{t("Modules")}</h2>
              </div>
              {modules.length > 0 && (
                <div className="modules-list flex-grow-1 scroll-wrap">
                  <ul className="list-unstyled">
                    {modules.map((level: any) => {
                      return (
                        <li
                          key={
                            "child_padding_dev_" +
                            level.value +
                            "_" +
                            Math.floor(Math.random() * 9999)
                          }
                          style={{
                            paddingLeft: `${level.depth > 0 ? level.depth * 10 + 10 : 10
                              }px`,
                          }}
                          className={classNames({
                            "clr-grey-txt": level.depth > 0,
                            active: selectedGroupId === level.value,
                            "not-active": selectedGroupId !== level.value,
                          })}
                        >
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              onSelectGroupId(level.value);
                            }}
                            className={classNames({
                              "clr-grey-txt": level.depth > 0,
                            })}
                          >
                            {level?.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              {modules.length === 0 && (
                <div className="empty-knowledge text-center my-5">
                  <p className="h6 clr-primary mb-0 font-weight-bold text-center">
                    {t("No modules available")}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="border-0 px-3">
        <button className="btn btn-outline-primary mr-2" onClick={props.onHide}>
          {t("Close")}
        </button>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            if (isGroupInvalid) {
              return;
            }
            confirmCategoryUpdatehandler(
              selectedGroupId,
              false,
              props.selectedFlow
            );
          }}
          disabled={selectedGroupId ? false : true}
        >
          {t("Save Changes")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default SelectModalModule;
