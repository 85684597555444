import { exportSubmissions, exportSubmissionsRolePlay } from "../../../store/knowledge/actions";
import React from "react";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import {includes} from "lodash";

export default function ExportSubmissionsHandler({
  exportName,
  userId,
  quizId,
  classId,
  filters,
  roleplay,
}: {
  exportName: string;
  quizId: string;
  userId?: string;
  classId?: string;
  roleplay?: boolean;
  filters?: { [key: string]: any };
}) {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const exportHandler = async () => {
    let exportData: any = {};
    if (classId) {
      exportData.class_id = classId;
    }
    if  (userId) {
      exportData.author_id = userId;
    }

    let k: keyof any;
    for (k in filters) {
      if (!["class_id", "author_id"].includes(k)) {
        exportData[k] = filters[k];
      }
}

    try {
      const blob: any = await dispatch(roleplay ? exportSubmissionsRolePlay(quizId, exportData) : exportSubmissions(quizId, exportData));

      if (!blob) {
        return;
      }

      let extension =
        blob &&
          blob.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? "xlsx"
          : "csv";
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${exportName}.${extension}`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) { }
  };

  return (
    <button className="btn btn-link" onClick={(e: any) => exportHandler()}>
      {t("Export")}
    </button>
  );
}

export const submissionsCustomStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#E6EDFF",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        padding: "9px 16px",
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#E6EDFF",
      padding: "10px 10px",
      fontSize: "14px",
      color: "#0E0748",
      borderRadius: "0px",
      fontWeight: "700",
      div: {
        color: "#0E0748",
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      padding: "10px 10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      p: {
        fontSize: "14px",
      },
      alignItems: "start",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#CDD5EF",
      },
    },
  },
};

export const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      // borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        padding: "9px 16px",
        // img: {
        //   // width: "10px",
        // },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "18px",
      },
      "&:last-child": {
        borderTopRightRadius: "18px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      width: "250px",
      padding: "10px 10px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      padding: "10px 10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      alignItems: "start",
      p: {
        fontSize: "14px",
      },
    },
  },
  rows: {
    style: {
      minHeight: "auto",
    },
  },
};