import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import chevLeft from "../../../../assets/New-images/chevron-left-blk.svg";
import { getClasses } from "../../../../store/dashboard/actions";
import {
  getCourseClassesById,
  getCourseLmsById,
  getUserGroupById,
  unlinkACourseToClass,
  updateClassDetailById,
  updateUserGroupNameById,
} from "../../../../store/knowledge/actions";
import { capitalizeFirstLetter } from "../../../../utils/appUtils";
import UmAdmins from "../Admins/UmAdmins";
import UmLearners from "../Learners/UmLearners";
import LearnerGroupUsers from "./LearnerGroupUsers";

import { useTranslation } from "react-i18next";

function UpdateLearnerGroup() {
  const { t } = useTranslation('translation');
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [classDetail, setClassDetail] = useState(null);
  const [activeKey, setActiveKey] = useState("admins");
  const [courseLMS, setCourseLMS] = useState(null);
  const [classes, setClasses] = useState([]);
  const [name, setName] = useState("");

  useEffect(() => {
    funGetLearnerGroupById();
    getAllClassesFunction();
  }, []);

  const getAllClassesFunction = async () => {
    try {
      const { classes }: any = await dispatch(getClasses());
      setClasses(classes);
    } catch (error: any) {
      setClasses([]);
    }
  };

  const funGetLearnerGroupById = async () => {
    try {
      const response: any = await dispatch(getUserGroupById(params.groupId));
      setName(response.name)
      setClassDetail(response);
    } catch (error: any) {
      history.goBack();
    }
  };

  const funUpdateClassDetailById = async (e: any) => {
    if (!classDetail.name) {
      return;
    }
    try {
      const response: any = await dispatch(
        updateUserGroupNameById(params.groupId, {
          name: classDetail?.name,
        })
      );
    } catch (error: any) {}
  };

  const [showCreateClassModal, setShowCreateClassModal] = useState(false);
  const showCreateClassModalHandler = async () => {
    setShowCreateClassModal(!showCreateClassModal);
  };

  return (
    <section className="page-mid-wraper h-without-foter">
      <div>
        <Row>
          <Col md={12}>
            <h1 className="mb-24 d-flex align-items-center">
              <button
                className="btn py-0 mr-2"
                onClick={() => {
                  history.push('/user-management?tab=learner_group');
                }}
              >
                <img src={chevLeft} alt="" />
              </button>
              {name ? name : 'Learner Group'}
            </h1>

            <h2 className="font-1 mb-1 font-600">{t("Manage Group")}</h2>

            <h2 className="mb-24 font-weight-normal">
              {t("Manage learners and admins within the learner group")}
            </h2>
          </Col>
          <Col md={12}>
            <div className="form-group mb-24">
              <label className="font-600 font-16">{t("Group Name")}</label>
              <div className="d-flex">
                <input
                  className="form-control h-auto radius-8"
                  placeholder={t("Name of Class")}
                  defaultValue={classDetail?.name || ""}
                  onChange={(e: any) => {
                    setClassDetail({
                      ...classDetail,
                      name: e.target.value,
                    });
                  }}
                />
                <button
                  disabled={!classDetail || !classDetail.name}
                  className={classNames({
                    "btn font-sm ml-3  text-nowrap": true,
                    "bg-grey-head border clr-grey-txt":
                      !classDetail || !classDetail.name,
                    "btn-primary": classDetail && classDetail.name,
                  })}
                  onClick={funUpdateClassDetailById}
                >
                  {t("Save Changes")}
                </button>
              </div>
            </div>

            {classDetail && (
              <div className="knowledge-viewby">
                <LearnerGroupUsers
                  data={classDetail.users}
                  getClassDetails={funGetLearnerGroupById}
                  classMode
                  classes={classes}
                  confirmHandler={funGetLearnerGroupById}
                  learnerGroupName={classDetail?.name}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default UpdateLearnerGroup;
