import DashboardService from "../../services/DashboardService";
import { HelperService } from "../../services/helper.service";
import { getDateOfISOWeek } from './../../utils/appUtils';
import * as type from "./actionType";
import * as _ from 'lodash';
import { t } from "i18next";
import { QuizSummarySubmissionInterface } from "./reducer";

type AppDispatchType = any;

const service = new DashboardService();
const helperService = new HelperService();

const calculateQuizCount = async (data: any) => {
    let submissionCount = 0;
    if (data.quiz_summary.quizzes) {
        data.quiz_summary.quizzes.forEach((quiz: QuizSummarySubmissionInterface) => {
            submissionCount += quiz.submission_count;
        });
    }
    return submissionCount;
}

export const getDashboardBaseData = () => async (
    dispatch: AppDispatchType
) => {
    try {
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                loading: true,
                baseDataLoading: true,
                error: false
            },
        });

        const response = await service.getDashboardUserReports();
        const responseConversationalTurns = await service.getDashboardConversationTurnsReports();
        const commentsResponse = await service.getLatestComments({});
        const quizSummary = await service.getDashboardQuizSummary({});
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                baseDataLoading: false,
                loading: false,
                session_users_count: response.data.data.session_users_count,
                sessions_count: response.data.data.sessions_count,
                conversation_turn_average: responseConversationalTurns.data.data.conversation_turn_average,
                conversation_turn_highest: responseConversationalTurns.data.data.conversation_turn_highest,
                comments: commentsResponse.data.data.report_data ? commentsResponse.data.data.report_data.rows : [],
                quizSubmissionsSummary: quizSummary.data.data.quiz_summary.quizzes ? quizSummary.data.data.quiz_summary.quizzes : [],
                quizSubmissionsSummaryCount: await calculateQuizCount(quizSummary.data.data)
            },
        });
    } catch (error: any) {
        console.log('< Base data error >', error);
        // helperService.showToaster('Something went wrong while updating hiccups.');
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                session_users_count: 0,
                sessions_count: 0,
                loading: false,
                quizSubmissionsSummary: [],
                quizSubmissionsSummaryCount: 0,
                baseDataLoading: false,
                error: true
            }
        });
    }
};

export const getDashboardUserReports = () => async (
    dispatch: AppDispatchType
) => {
    try {
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                loading: true,
                error: false
            },
        });

        const response = await service.getDashboardUserReports();
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                session_users_count: response.data.data.session_users_count,
                sessions_count: response.data.data.sessions_count
            },
        });
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                session_users_count: 0,
                sessions_count: 0,
                loading: false,
                error: true
            }
        });
    }
};

export const getDashboardConversationTurnsReports = () => async (
    dispatch: AppDispatchType
) => {
    try {
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                loading: true,
                error: false
            },
        });

        const response = await service.getDashboardConversationTurnsReports();
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                conversation_turn_average: response.data.data.conversation_turn_average,
                conversation_turn_highest: response.data.data.conversation_turn_highest
            },
        });
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                conversation_turn_average: 0,
                conversation_turn_highest: 0,
                loading: false,
                error: true
            }
        });
    }
};

export const getDashboardQuizSummary = (filter: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                loading: true,
                error: false
            },
        });

        const response = await service.getDashboardQuizSummary(filter);
        return Promise.resolve({
            quizzes: response.data.data.quiz_summary.quizzes
        })
    } catch (error: any) {
        return Promise.reject({
            quizzes: []
        })
    }
};

export const getDashboardQuizList = (filter: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                loading: true,
                error: false
            },
        });

        const response = await service.getDashboardQuizList(filter);
        return Promise.resolve({
            quizzes: response.data.data.report_data.rows
        })
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        return Promise.resolve({
            quizzes: []
        })
    }
};


export const getDashboardUserSessionReportsWeekly = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                loading: true,
                error: false
            },
        });
        const response = await service.getDashboardUserSessionReportsWeekly(filtersData);
        // console.log('response', response)
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                weekly_sessions_count: {
                    rows: response[0].data.data.report_data.rows.map((reportData: any) => {
                        return {
                            label: reportData.week,
                            x: reportData.week,
                            value: reportData.session_count,
                            y: reportData.session_count
                        }
                    }),
                    summary: response[0].data.data.report_data.summary
                },
                weekly_users_count: {
                    rows: response[1].data.data.report_data.rows.map((reportData: any) => {
                        return {
                            label: reportData.week,
                            x: reportData.week,
                            value: reportData.user_count,
                            y: reportData.user_count
                        }
                    }),
                    summary: response[1].data.data.report_data.summary
                }
            },
        });
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                weekly_sessions_count: [],
                weekly_users_count: []
            }
        });
    }
};

export const getDashboardUserSessionReports = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        let response;
        if (filtersData.type === 'weekly') {
            response = await service.getDashboardWeeklyActiveUserSessionReports(filtersData);
        } else {
            response = await service.getDashboardActiveUserSessionReports(filtersData);
        }
        const length = response.data.data.report_data.rows.length;
        return Promise.resolve({
            activeUsersSessionsLoading: false,
            activeUsersSessions: filtersData.type === 'weekly' ? response.data.data.report_data.rows.map((row: any) => {
                return {
                    ...row,
                    date: getDateOfISOWeek(row.week, new Date().getFullYear())
                }
            }): response.data.data.report_data.rows,
            sessionsCount: response.data.data.report_data.rows.map((session: any) => session.session_count).reduce(function (n: any, val: any) {
                return n + val
            }, 0),
            // sessionGrowth: ((response.data.data.report_data.rows[length-1] - response.data.data.report_data.rows[length-2]) / response.data.data.report_data.rows[length-2]) * 100,
            usersCount: response.data.data.report_data.rows.map((session: any) => session.user_count).reduce(function (n: any, val: any) {
                return n + val;
            }, 0),
            average_daily_session: response.data.data.report_data.summary.average_daily_session,
            average_daily_user: response.data.data.report_data.summary.average_daily_user
        })
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        return Promise.reject({
            activeUsersSessionsLoading: false,
            activeUsersSessions: [],
            sessionsCount: 0,
            usersCount: 0,
            average_daily_session: 0,
            average_daily_user: 0,
        })
    }
};

export const getDashboardUniqueActiveUsersSummary = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        let response = await service.getDashboardActiveUserSessionReports(filtersData);
        let getDashboardActiveUserSessionReports = await service.getDashboardUniqueActiveUsersSummary(filtersData);
        return Promise.resolve({
            activeUsersSessionsLoading: false,
            activeUsersSessions: filtersData.type === 'weekly' ? response.data.data.report_data.rows.map((row: any) => {
                return {
                    ...row,
                    date: getDateOfISOWeek(row.week, new Date().getFullYear())
                }
            }): response.data.data.report_data.rows,
            sessionsCount: response.data.data.report_data.rows.map((session: any) => session.session_count).reduce(function (n: any, val: any) {
                return n + val
            }, 0),
            // sessionGrowth: ((response.data.data.report_data.rows[length-1] - response.data.data.report_data.rows[length-2]) / response.data.data.report_data.rows[length-2]) * 100,
            usersCount: getDashboardActiveUserSessionReports.data.data.report_data.rows ? getDashboardActiveUserSessionReports.data.data.report_data.rows[0].unique_active_user : 0,
            average_daily_session: response.data.data.report_data.summary.average_daily_session,
            average_daily_user: response.data.data.report_data.summary.average_daily_user
        })
    } catch (error: any) {
        return Promise.reject({
            activeUsersSessionsLoading: false,
            activeUsersSessions: [],
            sessionsCount: 0,
            usersCount: 0,
            average_daily_session: 0,
            average_daily_user: 0,
        })
    }
};

export const getDashboardTopQuestions = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        // dispatch({
        //     type: type.FETCH_DASHBOARD_SESSION_COUNT,
        //     payload: {
        //         loading: true,
        //         error: false
        //     },
        // });
        const response = await service.getDashboardTopQuestions(10, filtersData);
        return Promise.resolve({
            top_questions: response.data.data.report_data.rows
        })
        // dispatch({
        //     type: type.FETCH_DASHBOARD_SESSION_COUNT,
        //     payload: {
        //         top_questions: response.data.data.top_questions
        //     },
        // });
    } catch (error: any) {
        return Promise.reject({
            top_questions: []
        })
        // helperService.showToaster('Something went wrong while updating hiccups.');
        // dispatch({
        //     type: type.FETCH_DASHBOARD_SESSION_COUNT,
        //     payload: {
        //         top_questions: [],
        //         loading: false,
        //         error: true
        //     }
        // });
    }
};

export const getLatestComments = (filterData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getLatestComments(filterData);
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                comments: response.data.data.report_data ? response.data.data.report_data.rows : []
            },
        });
        return Promise.resolve({
            comments: response.data.data.report_data ? response.data.data.report_data.rows : []
        })
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        dispatch({
            type: type.FETCH_DASHBOARD_SESSION_COUNT,
            payload: {
                comments: [],
                loading: false,
                error: true
            }
        });
        return Promise.reject({
            comments: []
        })
    }
};

export const getQuizSubmissions = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        // dispatch({
        //     type: type.FETCH_DASHBOARD_SESSION_COUNT,
        //     payload: {
        //         quizSubmissionsLoading: true
        //     },
        // });
        let response: any;
        let updatedResponse: any[];
        if (filtersData.frequency === 'weekly') {
            response = await service.getWeeklyQuizSubmissions(filtersData);
            updatedResponse = await response.data.data.report_data.rows.map((reportData: any) => {
                return {
                    submission_count: reportData.submission_count,
                    date: reportData.week
                }
            })
        } else {
            response = await service.getQuizSubmissions(filtersData);
        }
        return Promise.resolve({
            data: filtersData.frequency === 'weekly' ? updatedResponse : response.data.data.report_data.rows,
            count: response.data.data.report_data.rows.map((session: any) => session.submission_count).reduce(function (n: any, val: any) {
                return n + val
            }, 0)
        })
        // dispatch({
        //     type: type.FETCH_DASHBOARD_SESSION_COUNT,
        //     payload: {
        //         quizSubmissionsLoading: false,
        //         quizSubmissions: response.data.data.report_data ? response.data.data.report_data.rows.map((row: any) => {
        //             return {
        //                 ...row,
        //                 type: 'quizsubmission'
        //             }
        //         }) : []
        //     },
        // });
    } catch (error: any) {
        return Promise.reject({
            data: [],
            count: 0
        })
        // helperService.showToaster('Something went wrong while updating hiccups.');
        // dispatch({
        //     type: type.FETCH_DASHBOARD_SESSION_COUNT,
        //     payload: {
        //         quizSubmissions: [],
        //         quizSubmissionsLoading: false
        //     }
        // });
    }
};

export const getRatingsSummary = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        // dispatch({
        //     type: type.FETCH_RATINGS_SUMMARY,
        //     payload: {
        //         ratingSummaryLoading: true
        //     },
        // });
        const response = await service.getRatingsSummary(filtersData);
        return Promise.resolve({
            ratingSummaryLoading: false,
            ratingSummary: response.data.data.report_data ? response.data.data.report_data : []
        })
        // dispatch({
        //     type: type.FETCH_RATINGS_SUMMARY,
        //     payload: {
        //         ratingSummaryLoading: false,
        //         ratingSummary: response.data.data.report_data ? response.data.data.report_data : []
        //     },
        // });
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        // dispatch({
        //     type: type.FETCH_RATINGS_SUMMARY,
        //     payload: {
        //         ratingSummary: {
        //             rows: [],
        //             summary: {
        //                 rating_average: 0,
        //                 rating_count: 0
        //             }
        //         },
        //         ratingSummaryLoading: false
        //     }
        // });
        return Promise.resolve({
            ratingSummary: {
                rows: [],
                summary: {
                    rating_average: 0,
                    rating_count: 0
                }
            },
            ratingSummaryLoading: false
        })
    }
};

export const getConversationTurnSummary = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        // dispatch({
        //     type: type.FETCH_RATINGS_SUMMARY,
        //     payload: {
        //         ratingSummaryLoading: true
        //     },
        // });
        const conversationTurnSummary = await service.getConversationTurnSummary(filtersData);
        const conversationTurns = await service.getConversationTurns(filtersData);
        return Promise.resolve({
            conversationTurnSummary: conversationTurnSummary.data.data.report_data.rows ? conversationTurnSummary.data.data.report_data.rows : [],
            conversationTurns: conversationTurns.data.data.report_data.rows ? conversationTurns.data.data.report_data.rows : []
        });

        // dispatch({
        //     type: type.FETCH_RATINGS_SUMMARY,
        //     payload: {
        //         ratingSummaryLoading: false,
        //         ratingSummary: response.data.data.report_data ? response.data.data.report_data : []
        //     },
        // });
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        // dispatch({
        //     type: type.FETCH_RATINGS_SUMMARY,
        //     payload: {
        //         ratingSummary: {
        //             rows: [],
        //             summary: {
        //                 rating_average: 0,
        //                 rating_count: 0
        //             }
        //         },
        //         ratingSummaryLoading: false
        //     }
        // });
        return Promise.reject([])
    }
};

export const matchedAndUnmatchedSummary = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        // dispatch({
        //     type: type.FETCH_RATINGS_SUMMARY,
        //     payload: {
        //         ratingSummaryLoading: true
        //     },
        // });
        const getMatchedAndUnmatchedQuestions = await service.getMatchedAndUnmatchedQuestions(filtersData);
        return Promise.resolve(getMatchedAndUnmatchedQuestions.data.data);
        // dispatch({
        //     type: type.FETCH_RATINGS_SUMMARY,
        //     payload: {
        //         ratingSummaryLoading: false,
        //         ratingSummary: response.data.data.report_data ? response.data.data.report_data : []
        //     },
        // });
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        // dispatch({
        //     type: type.FETCH_RATINGS_SUMMARY,
        //     payload: {
        //         ratingSummary: {
        //             rows: [],
        //             summary: {
        //                 rating_average: 0,
        //                 rating_count: 0
        //             }
        //         },
        //         ratingSummaryLoading: false
        //     }
        // });
        return Promise.reject({
            questions_matched_count: 0,
            questions_unmatched_count: 0,
        })
    }
};

export const getAllUsersDashboard = () => async (
    dispatch: AppDispatchType
) => {
    try {
        const users = await service.getAllUsers();
        return Promise.resolve(users.data.data.users);
    } catch (error: any) {
        return Promise.reject([])
    }
};

export const getAllGroupUsersDashboard = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const users = await service.getAllGroupUsers(filtersData);
        return Promise.resolve(users.data.data.report_data);
    } catch (error: any) {
        return Promise.reject([])
    }
};

export const getAllGroupUsersModalDashboard = (filtersData: any, _id: any, filter_is_attempted: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const users = await service.getAllGroupUsersModal(filtersData,_id,filter_is_attempted);
        return Promise.resolve(users.data.data.report_data);
    } catch (error: any) {
        return Promise.reject([])
    }
};

export const getTopUsers = (filtersData: any, page: number = null, page_size: number = null) => async (
    dispatch: AppDispatchType
) => {
    try {
        const users = await service.getTopUsers(filtersData, page, page_size);
        return Promise.resolve(users.data.data.report_data);
    } catch (error: any) {
        return Promise.reject([])
    }
};

export const getReportsAverageUsageDuration = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const users = await service.getReportsAverageUsageDuration(filtersData);
        return Promise.resolve(users.data.data.report_data);
    } catch (error: any) {
        return Promise.reject([])
    }
};

export const getReportsDailyActiveSession = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const users = await service.getDailyActiveSessionReport(filtersData);
        return Promise.resolve(users.data.data.report_data);
    } catch (error: any) {
        return Promise.reject([])
    }
};

export const getAverageMonthlySessionsReports = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getDashboardAverageMonthlyActiveUsersSessionReports(filtersData);
        const length = response.data.data.report_data.rows.length;
        return Promise.resolve({
            averageMonthlyUserSessionsLoading: false,
            averageMonthlyUserSessions: response.data.data.report_data.rows,
            sessionsCount: response.data.data.report_data.rows.map((session: any) => session.session_count).reduce(function (n: any, val: any) {
                return n + val
            }, 0),
            // sessionGrowth: ((response.data.data.report_data.rows[length-1] - response.data.data.report_data.rows[length-2]) / response.data.data.report_data.rows[length-2]) * 100,
            usersCount: response.data.data.report_data.rows.map((session: any) => session.user_count).reduce(function (n: any, val: any) {
                return n + val;
            }, 0),
            average_monthly_user: response.data.data.report_data.summary.average_monthly_user,
            average_monthly_session: response.data.data.report_data.summary.average_monthly_session
        })
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        return Promise.reject({
            averageMonthlyUserSessionsLoading: false,
            averageMonthlyUserSessions: [],
            sessionsCount: 0,
            usersCount: 0,
            average_monthly_user: 0,
            average_monthly_session: 0,
        })
    }
};

export const getDailyReturningUsers = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getDailyReturningUsers(filtersData);
        const length = response.data.data.report_data.rows.length;
        return Promise.resolve({
            dailyReturningUsersLoading: false,
            dailyReturningUsers: response.data.data.report_data.rows,
            returningUserCount: response.data.data.report_data.rows.map((session: any) => session.returning_user).reduce(function (n: any, val: any) {
                return n + val
            }, 0),
            total_returning_users: response.data.data.report_data.summary.total_returning_users,
            average_returning_users: response.data.data.report_data.summary.average_returning_users
        })
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        return Promise.reject({
            dailyReturningUsersLoading: false,
            dailyReturningUsers: [],
            returningUserCount: 0,
            usersCount: 0,
            total_returning_users: 0,
            average_returning_users: 0,
        })
    }
};

export const getMonthlyReturningUsers = (filtersData: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getMonthlyReturningUsers(filtersData);
        const length = response.data.data.report_data.rows.length;
        return Promise.resolve({
            dailyReturningUsersLoading: false,
            dailyReturningUsers: response.data.data.report_data.rows,
            returningUserCount: response.data.data.report_data.rows.map((session: any) => session.returning_user).reduce(function (n: any, val: any) {
                return n + val
            }, 0),
            total_returning_users: response.data.data.report_data.summary.total_returning_users,
            average_returning_users: response.data.data.report_data.summary.average_returning_users
        })
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        return Promise.reject({
            dailyReturningUsersLoading: false,
            dailyReturningUsers: [],
            returningUserCount: 0,
            usersCount: 0,
            total_returning_users: 0,
            average_returning_users: 0,
        })
    }
};

export const getClasses = () => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getClasses();
        let classes = [];
        classes = response.data.data.classes.map((classobj: any) => {
            return {
                ...classobj,
                label: classobj.name,
                labelForSorting: classobj.name.toLowerCase(),
                value: classobj._id,
                connectionIds: classobj.connections.map((c: { channel_id: string, connection_id: string }) => c.connection_id).join(','),
                studentGroupId: classobj.student_group_id,
                adminGroupId: classobj.admin_group_id,
            }
        });
        return Promise.resolve({
            classes: _.orderBy([ ...classes ], ['labelForSorting'], ['asc'])
        })
    } catch (error: any) {
        return Promise.reject({
            classes: []
        })
    }
};

export const getTopUsersReports = (filter: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getTopUsersReports(filter);
        return Promise.resolve({
            topUsers: response.data.data.top_users
        })
    } catch (error: any) {
        return Promise.reject({
            topUsers: []
        })
    }
};

export const getQuizDetailByQuizId = (quizId: any, filter: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getQuizDetailByQuizId(quizId, filter);
        return Promise.resolve({
            rows: response.data.data.report_data.rows,
            pagination: response.data.data.report_data.pagination
        })
    } catch (error: any) {
        return Promise.reject({
            rows: [],
            pagination: null
        })
    }
};


export const getQuizDetailByUser = (quizId: any, userId: any, userType: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getQuizDetailByUser(quizId, userId, userType);
        return Promise.resolve({
            quiz_submissions: response.data.data.quiz_submissions
        })
    } catch (error: any) {
        return Promise.reject({
            quiz_submissions: []
        })
    }
};

export const getQuizByQuizId = (quizId: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const quizDetailById = await service.getQuizDetailById(quizId);
        return Promise.resolve({
            quizDetail: quizDetailById.data.data.quiz
        })
    } catch (error: any) {
        return Promise.resolve({
            quizDetail: null
        })
    }
};

export const getRoleplayPerformanceReport = (params: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const quizDetailById = await service.getRoleplayPerformanceReport(params);
        return Promise.resolve({
            quizDetail: quizDetailById?.data?.data?.criteria
        })
    } catch (error: any) {
        return Promise.resolve({
            quizDetail: null
        })
    }
};

export const getQuizRoleplaySubmissions = (quizId: any, filter: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getQuizRoleplaySubmissions(quizId, filter);
        return Promise.resolve({
            rows: response?.data?.data?.roleplay_submissions,
            pagination: response?.data?.data?.pagination
        })
    } catch (error: any) {
        return Promise.reject({
            rows: [],
            pagination: null
        })
    }
};

export const getStudentsYetToAttempt = (quizId: any, filter: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getStudentsYetToAttempt(quizId, filter);
        return Promise.resolve({
            rows: response.data.data.report_data.rows,
            pagination: response.data.data.report_data.pagination,
        })
    } catch (error: any) {
        return Promise.reject({
            rows: [],
            pagination: null
        })
    }
};

export const getQuizTotalNUmberOfSubmissionsCount = (quizId: any, filter: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getQuizTotalNUmberOfSubmissionsCount(quizId, filter);
        return Promise.resolve(response.data.data.report_data?.rows)
    } catch (error: any) {
        return Promise.reject(0)
    }
};

export const getQuizDetailFirstSubmissionSummary = (quizId: any, filter: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getQuizDetailFirstSubmissionSummary(quizId, filter);
        return Promise.resolve(response.data.data.report_data?.rows)
    } catch (error: any) {
        return Promise.reject(0)
    }
};

export const exportReportUsingDateAndType = (report_name: string, file_format: string, filtersData?: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.exportReportUsingDateAndType(report_name, file_format, filtersData);
        return Promise.resolve(response.data)
    } catch (error: any) {
        return Promise.reject(null)
    }
};

export const getLearnerSubmissions = (user_id: string) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getLearnerSubmissions(user_id);
        let preData: any = {};

        response.data.data.submissions.forEach((item: any) => {
            if (item.topic_name) {
                if (!(item.topic_name in preData)) {
                    preData[item.topic_name] = {
                        id: item.topic_name,
                        data: [],
                    };
                }

                // if (preData[item.topic_name].data.length < 15) {
                preData[item.topic_name] = {
                    id: item.topic_name,
                    data: preData[item.topic_name].data.concat([
                        {
                            x: item.take,
                            y: item.score,
                        },
                    ]),
                };
                // }
            }
        });
        return Promise.resolve(Object.values(preData))
    } catch (error: any) {
        console.log('err', error)
        // helperService.showToaster('Something went wrong while updating hiccups.');
        return Promise.reject([])
    }
};

export const getLearnerSessionsReport = (user_id: string) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getLearnerSessionsReport(user_id);
        return Promise.resolve(response.data.data.sessions)
    } catch (error: any) {
        // helperService.showToaster('Something went wrong while updating hiccups.');
        return Promise.reject([])
    }
};


export const getStudentSessionSummaryReport = (session_id: string) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getStudentSessionSummaryReport(session_id);
        return Promise.resolve(response.data.data)
    } catch (error: any) {
        return Promise.reject([])
    }
};

export const getMetaBaseData = (session_id: string) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getMetaBaseData(session_id);
        return Promise.resolve(response.data.data)
    } catch (error: any) {
        return Promise.reject([])
    }
}


export const getUserFeedbacks = (payload : any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const res = await service.getFeedbacks(payload);
        return Promise.resolve(res.data.data.report_data)
    } catch (error: any) {
        return Promise.reject([])
    }
}

export const getUserConversations = (payload: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getUserConversations(payload);
        return Promise.resolve(response.data.data)
    } catch (error: any) {
        return Promise.reject([])
    }
}

export const downloadUserConversations = (payload: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.downloadUserConversations(payload);
        return Promise.resolve(response.data)
    } catch (error: any) {
        return Promise.reject([])
    }
}

export const getSubmissionReviewCount = (quizId: string = null, filter: any = {}) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getSubmissionReviewCount( quizId, filter );
        return Promise.resolve(response.data.data.submission_review_count)
    } catch (error: any) {
        return Promise.reject([])
    }
}

export const sendReminders = (quiz_id: string, data: string[]) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.sendReminders(quiz_id, data);
        helperService.showToaster(t("Reminder successfully sent."), 'success')
        return Promise.resolve(null)
    } catch (error: any) {
        helperService.showToaster(t("Something went wrong please try again later."))
        return Promise.reject([])
    }
}

export const getQuestionCorrectnessReport = (quiz_id: string, filters: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getQuestionCorrectnessReport(quiz_id, filters);
        return Promise.resolve(response.data.data.questions)
    } catch (error: any) {
        helperService.showToaster(t("Something went wrong please try again later."))
        return Promise.reject([])
    }
}

export const getQuestionAnalyticsReport = (quiz_id: string, filters: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getQuestionAnalyticsReport(quiz_id, filters);
        return Promise.resolve(response.data.data.questions)
    } catch (error: any) {
        helperService.showToaster(t("Something went wrong please try again later."))
        return Promise.reject([])
    }
}
export const getQuestionAnalyticsSubmissionReport = (quiz_id: string, filters: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.getQuestionAnalyticsSubmissionReport(quiz_id, filters);
        return Promise.resolve(response.data.data)
    } catch (error: any) {
        return Promise.reject([])
    }
}


export const getQuizSubmissionsList = (filter: any) => async (
    dipatch: AppDispatchType
) => {
    try {
        const response = await service.getReportQuizSubmissionsList(filter);
        return Promise.resolve(response.data.data.report_data)
    } catch (error: any) {
        return Promise.reject([])
    }
 } 

 export const ExportDiscussions = (data: any) => async (
    dispatch: AppDispatchType
) => {
    try {
        const response = await service.ExportDiscussions(data);
        return Promise.resolve(response.data)
    } catch (error: any) {
        return Promise.reject(null)
    }
};