/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionContext,
  Dropdown,
  useAccordionToggle,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addRoleUserToAdmins,
  deleteAdminsFromClasses,
  resendInvite,
} from "../../../../store/knowledge/actions";
import { getAgentFromStorage } from "../../../../utils/appUtils";

import { useParams } from "react-router";
import sortIcon from "../../../../assets/New-images/sort-blue-icon.svg";
import sortAsc from '../../../../assets/images/arrow-sort-asc.svg';
import sortDsc from '../../../../assets/images/arrow-sort-dsc.svg';
import AdminLearnerTile from "../../../../components/UI/AdminLearnerTile/AdminLearnerTile";
import ConfirmationModal from "../../../../components/UI/ConfirmationModal/ConfirmationModal";
import Loading from "../../../../components/UI/Loading/Loading";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import AddAdminModal from "../../../OrganizationUsersAdmin/AddAdminModal";
import * as _ from 'lodash';
import { HelperService } from "../../../../services/helper.service";
import Pagination from "../../../../components/UI/Pagination/Pagination";
import AddMultipleAdmins from "../Admins/AddMultipleAdmins";
import { useTranslation } from "react-i18next";

const ClassAdmins = (props: any) => {
  const { t } = useTranslation('translation');
  const windoeObj: any = window;
  const helperService = new HelperService();
  const [iframeLoading, setiframeLoading] = useState(false);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const { classId }: any = useParams();
  const [filters, setFilters] = useState({
    page: 1,
    page_size: 20,
    sort_field: null,
    sort_order: null,
  });
  const [groups, setGroups] = useState<any>([]);
  const [searchedGroups, setSearchedGroups] = useState<any>([]);
  const [paginationState, setPaginationState] = useState(null);
  const [paginatedGroups, setPaginatedGroups] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadListModalShow, setUploadListModalShow] = useState(false);
  const uploadListModalShowHandler = async () => {
    setUploadListModalShow(!uploadListModalShow);
  };

  useEffect(() => {
    setGroups([...props.data]);
    setSearchedGroups([...props.data]);
    paginate([...props.data])
  }, [props]);

  const iframeLoadedHandler = () => {
    setiframeLoading(false);
  };

  const addRoleUserToAdminsHandler = async (
    id: string,
    role: string,
    isSuperadmin: boolean
  ) => {
    const agentId: string = getAgentFromStorage();
    if (!agentId) {
      return;
    }
    try {
      setiframeLoading(true);
      const response: any = await dispatch(
        addRoleUserToAdmins(agentId, id, role, isSuperadmin)
      );
      //   func();
      setiframeLoading(false);
    } catch (error: any) {
      setiframeLoading(false);
    }
  };

  const deleteAdminsFromRolesHandler = async (
    eve: any,
    e: {
      id: string;
      role: string;
    }
  ) => {
    setAdminDeleteModalShow(false);
    try {
      setiframeLoading(true);
      const response: any = await dispatch(
        deleteAdminsFromClasses(classId, e.id)
      );
      props.getClassDetails && props.getClassDetails();
      setiframeLoading(false);
    } catch (error: any) {
      setiframeLoading(false);
    }
  };

  const addAdminToggleHandler = () => {
    setEditMode(false);
    setEditData(null);
    setShowAdminModal(!showAdminModal);
  };

  const confirmHandler = () => {
    setShowAdminModal(false);
    setUploadListModalShow(false);
    props.getClassDetails && props.getClassDetails();
  };

  const editAdminToggleHandler = (data: any, isEdit: boolean) => {
    setEditMode(true);
    setEditData(data);
    setShowAdminModal(!showAdminModal);
  };

  const [deletedRowId, setDeletedRowId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const resendInviteHandler = async (e: any) => {
    try {
      setDeletedRowId(e);
      setModalShow(true);
    } catch (error: any) { }
  };

  const [deletedAdminId, setDeletedAdminId] = useState(null);
  const [adminDeleteModalShow, setAdminDeleteModalShow] = useState(false);
  const deleteAdminHandler = async (id: string, role: string) => {
    try {
      setDeletedAdminId({
        id,
        role,
      });
      setAdminDeleteModalShow(true);
    } catch (error: any) { }
  };

  const deleteConfirmed = async (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedRow: any
  ) => {
    e.preventDefault();
    setModalShow(false);
    await dispatch(resendInvite(selectedRow._id));
    // func();
  };

  const searchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const term = e.currentTarget.value;
    const searchedQuestionData =
      e.currentTarget.value.trim().length > 0
        ? groups.filter(
          (v: any) =>
            (v.first_name &&
              v.first_name.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
            (v.email &&
              v.email.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
            (v.last_name &&
              v.last_name.toLowerCase().indexOf(term.toLowerCase()) > -1)
        )
        : groups;
    setSearchedGroups(searchedQuestionData);
    paginate(searchedQuestionData)
  };

  const sort = (sort_field: string, sort_order: any) => {
    setFilters({
      ...filters,
      sort_field,
      sort_order,
    });
    let sortedList = [...searchedGroups];
    const updatedList = _.orderBy(sortedList, [sort_field], [sort_order]);
    setSearchedGroups(updatedList);
    paginate(updatedList)
  };

  const onSelectGridSortBar = (sort_field: string) => {
    if (filters.sort_field === sort_field) {
      if (filters.sort_order === "asc") {
        sort(sort_field, "desc");
      } else {
        sort(sort_field, "asc");
      }
    } else {
      sort(sort_field, "asc");
    }
  };

  useEffect(() => {
    searchedGroups && searchedGroups.length > 0 && paginate(searchedGroups)
    return () => { }
  }, [currentPage, perPage])

  const paginate = (comments: any[]) => {
    const paginationData = helperService.paginator(
      comments,
      currentPage,
      perPage
    )
    setPaginationState({
      ...paginationState,
      page: currentPage,
      page_size: perPage,
      total_items: comments.length,
      total_pages: paginationData.total_pages,
    })
    setPaginatedGroups([...paginationData.data])
  }

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setCurrentPage(page)
  }

  return (
    <>
      {iframeLoading && <Loading />}
      <AddAdminModal
        show={showAdminModal}
        onHide={addAdminToggleHandler}
        agentId={getAgentFromStorage()}
        confirmHandler={confirmHandler}
        editMode={editMode}
        editData={editData}
        classMode
      />

      <AddMultipleAdmins
        show={uploadListModalShow}
        onHide={uploadListModalShowHandler}
        agentId={getAgentFromStorage()}
        confirmHandler={confirmHandler}
        classMode
      />

      <ConfirmationModal
        message={t("Are you sure you want to remove this admin?")}
        size={"md"}
        show={adminDeleteModalShow}
        selectedRow={deletedAdminId}
        onHide={() => setAdminDeleteModalShow(false)}
        confirmHandler={deleteAdminsFromRolesHandler}
      />

      <ConfirmationModal
        message={t("Are you sure want to resend the invite?")}
        size={"md"}
        show={modalShow}
        selectedRow={deletedRowId}
        onHide={() => setModalShow(false)}
        confirmHandler={deleteConfirmed}
        buttonText={"Resend Invite"}
      />

      <div className="all-knowledge-search d-flex flex-wrap align-items-center">
        <Dropdown className="sort-dropdown">
          <Dropdown.Toggle
            className="btn btn-outline-primary font-600 py-1 my-1 mr-3"
            variant="success"
            id="dropdown-basic"
          >
            <img src={sortIcon} alt="" className="mr-2" />
            {t("Sort")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                sort("first_name", "asc");
              }}
            >
              {t("Ascending")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                sort("first_name", "desc");
              }}
            >
              {t("Descending")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="web-links my-1">
          <input
            type="text"
            className="form-control h-auto"
            placeholder={t("Search for user")}
            onChange={searchHandler}
          />
        </div>

        <PermissionWrapper>
          <Dropdown className="sort-dropdown ml-auto">
            <Dropdown.Toggle
              className="btn btn-outline-primary font-600 border-0 py-1"
              variant="default"
              id="dropdown-basic"
            >
              {t("+ Add")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={addAdminToggleHandler}>
                {t("Add User by Email")}
              </Dropdown.Item>
              <Dropdown.Item onClick={uploadListModalShowHandler}>
                {t("Add Multiple User by Email")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </PermissionWrapper>
      </div>
      {/* <div className="d-flex flex-wrap justify-content-between align-items-center mt-2 pl-lg-3">
        <div className="d-flex align-items-center mt-1">
          <div className="custom-checkbox mr-3">
            <input
              type="checkbox"
              className="mr-2"
              id="select-all"
              checked={
                allKnowledgeSuccess &&
                allKnowledgeSuccess.intents &&
                allKnowledgeSuccess.intents.length > 0 &&
                selectedNodes.length ===
                  allKnowledgeSuccess.intents.length
              }
              onChange={onSelectAllRows}
            />
            <label htmlFor="select-all" className="mb-0">
              Select all
            </label>
          </div>
        </div>
      </div> */}
      <Accordion
        // defaultActiveKey={
        //   allKnowledgeSuccess && allKnowledgeSuccess.intents.length > 0
        //     ? allKnowledgeSuccess.intents[0]._id
        //     : null
        // }
        className="accordion-viewby update-class mt-3">
        <Row className="grid-sorting-bar pl-0">
          <Col
            className={`col-lg-3 col-6 ${filters.sort_field === "first_name" && "selected leftmost"}`}
            onClick={() => {
              onSelectGridSortBar("first_name");
            }}>
            <span>{t("First Name")}</span>
            {filters.sort_field === "first_name" && (filters.sort_order === "asc" ? (
              <Image className="sort-icon" src={sortAsc} />
            ) : (
              <Image className="sort-icon" src={sortDsc} />
            ))}
          </Col>
          <Col
            className={`col-lg-2 col-6 ${filters.sort_field === "last_name" && "selected"}`}
            onClick={() => {
              onSelectGridSortBar("last_name");
            }}>
            <span>{t("Last Name")}</span>
            {filters.sort_field === "last_name" && (filters.sort_order === "asc" ? (
              <Image className="sort-icon" src={sortAsc} />
            ) : (
              <Image className="sort-icon" src={sortDsc} />
            ))}
          </Col>
          <Col
            className={`col-lg-5 col-6 ${filters.sort_field === "email" && "selected"}`}
            onClick={() => {
              onSelectGridSortBar("email");
            }}>
            <span>{t("Email")}</span>
            {filters.sort_field === "email" && (filters.sort_order === "asc" ? (
              <Image className="sort-icon" src={sortAsc} />
            ) : (
              <Image className="sort-icon" src={sortDsc} />
            ))}
          </Col>
          <Col
            className={`col-lg-2 col-6 ${filters.sort_field === "status" && "selected rightmost"}`}
            onClick={() => {
              onSelectGridSortBar("status");
            }}>
            <span>{t("Status")}</span>
            {filters.sort_field === "status" && (filters.sort_order === "asc" ? (
              <Image className="sort-icon" src={sortAsc} />
            ) : (
              <Image className="sort-icon" src={sortDsc} />
            ))}
          </Col>
        </Row>
        {paginatedGroups.map((user: any, index: number) => {
          return (
            <AdminLearnerTile
              user={user}
              editAdminToggleHandler={editAdminToggleHandler}
              deleteAdminHandler={deleteAdminHandler}
              classMode
              isAdmin
              RemoveButtonTitle={t(`Remove from ${props?.className} class`)}
            />
          );
        })}
      </Accordion>
      {searchedGroups && searchedGroups.length > 0 && paginationState && (
        <Pagination
          currentPage={currentPage}
          pagination={{
            ...paginationState,
          }}
          paginationPerPage={perPage}
          pageChange={(newPerPage: number, page: number) => {
            setPerPage(newPerPage);
            setCurrentPage(1);
          }}
          onChangePage={handlePageChange}
          hideSuggestedQuestion
        />
      )}
    </>
  );
};

export default ClassAdmins;
